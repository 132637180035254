*,
*::before,
*::after {
    box-sizing: border-box;
}

.carousel-slider-image {
    flex-shrink: 0;
    flex-grow: 0;
    transition: translate 0.5s ease-in-out;
}

.carousel-slider-button {
    all: unset;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer;
    font-size: 2rem;
    transition: background-color 0.5s ease-in-out;
}

.carousel-slider-button:hover {
    background-color: rgb(0,0,0,0.4);
    transition: background-color 0.5s ease-in-out;
}

.image-index-buttons-container {
    bottom: 1rem;
    left: 50%;
    translate: -50%;
    gap: .30rem;
}

.image-index-buttons {
    all: unset;
    font-size: small;
    transition: scale 0.2s ease-in-out;
    width: 100%;
    font-size: 0.8rem;
}

.image-index-buttons:hover {
    scale: 1.3;
    transition: scale 0.2s ease-in-out;
}




