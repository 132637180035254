.chat-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 300px;
    border: 3px solid #3061e3;
    border-radius: 12px;
    background-color: #fff;
    margin-left: auto;
}

.chat-header {
    background-color: #3061e3;
    height: 75px;
}

.chat-messages {
    height: 350px;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

.message {
    padding: 0.5rem;
    margin: 0.5rem 0;
    word-wrap: break-word;
    max-width: 70%;
}

.message.user {
    background-color: #b6c7f5;
    text-align: right;
    margin-left: auto;
    margin-right: 0.5rem;
    border-radius: 16px 16px 0px 16px;
}

.message.assistant {
    background-color: #ffd78f;
    text-align: left;
    margin-left: 0.5rem;
    margin-right: auto;
    border-radius: 16px 16px 16px 0px;
}

.chat-line {
    height: 1px;
    margin: 0.5rem 0.5rem;
    background-color: #b4b4b4;
}

input[type="text"] {
    width: 80%;
    padding: 0.5rem;
    margin: 0.5rem;
    border: 0px;
    border-radius: 8px;
}

button {
    all: unset;
}

.send-button {
    background: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

.send-button:focus-visible {
    outline: 2px solid black;
}

.chatbot-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #3061e3;
    background-color: black;
    margin-left: auto;
    cursor: pointer;
}

.chat-container.closed {
    visibility: hidden;
}

.chatbot-icon.open {
    position: fixed;
    bottom: 30rem;
    right: 7.9rem;
}

.chatbot-icon.closed {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

.chat-container.open {
    visibility: visible;
}