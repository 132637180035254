/* Sidebar styles */
.sidebar-column {
  width: 250px;
  height: 100vh;
  position: sticky;
  top: 0;
  transition: transform 0.3s ease;
}

.sidebar-toggle {
  display: none; /* Sidebar button hidden by default */
}

@media screen and (max-width: 767px) {
  .sidebar-column {
    display: none; /* Sidebar hidden by default */
  }

  .sidebar-toggle {
    display: block; /* Sidebar button hidden by default */
  }

  .sidebar-visible {
    background-color: #ffd78f;
    display: block;
    position: fixed;
    z-index: 1000;
    transform: translateX(0); /* Slide-in */
  }

  .sidebar-hidden {
    transform: translateX(-100%); /* Slide-out */
  }

  /* Overlay for darkening the background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent overlay */
    z-index: 999; /* Below the sidebar */
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none; /* Prevent clicks when invisible */
  }

  /* Show overlay when sidebar is visible */
  .overlay-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

/* Sidebar toggle button */
.sidebar-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #3061e3;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

.sidebar-toggle:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  [data-aos] {
    pointer-events: auto !important;
    opacity: 1 !important;
    transform: none !important;
  }
}

.fit-content {
  min-width: fit-content;
  min-height: 100vh;
}

.sidebar-layout {
  margin: 0 auto;
  max-width: 62rem;
}

.no-decoration {
  text-decoration: none !important;
}

/* -------------------------------------------------------------
------------------------ Color Styles --------------------------
----------------------------------------------------------------*/

.sonic-beige {
  background-color: #ffd78f;
}

.sonic-blue {
  background-color: #3061e3;
}

.sonic-red {
  background-color: #da2528;
}

.sonic-blue-text {
  color: #3061e3;
}

.sonic-grey-text {
  color: #252525;
}

.sonic-beige-text {
  color: #ffd78f
}

/* -------------------------------------------------------------
----------------------- Menu Bar Styles ------------------------
----------------------------------------------------------------*/

.App-sidebar {
  width: 15rem;
  height: 100vh;
  position: sticky;
  top: 0;
}

.App-sidebar-stuff {
  border-right: 1px solid #505050;
  height: 100%;
}

.App-sidebar-horizontal-line {
  width: 90%;
  height: 1px;
  background-color: #505050;
}

.social-media-icon {
  width: 30px;
  height: 30px;
  transition: opacity 0.1s linear;
}

.social-media-icon:hover {
  opacity: 50%;
  transition: opacity 0.2s linear;
}

.social-media-icon:focus-visible {
  opacity: 50%;
  outline: 2px solid #000;
  border-radius: 4px;
}

/* -------------------------------------------------------------
----------------------- Content Styles -------------------------
----------------------------------------------------------------*/

.App-content {
  flex: 1;
  /* Other content styles */
}

.App-content-stuff {
  padding-left: 1rem;
}

.header-container {
  height: 100vh;
}

.topic-line {
  max-width: 10rem;
  height: 3px;
  margin-bottom: 1rem;
}

.sonic-card-no-link {
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.25);
  width: 95%;
  background-color: #f19b00;
  padding: 15px 25px;
}

.sonic-card {
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.25);
  width: 95%;
  background-color: #00f19b;
  padding: 15px 25px;
  transition: box-shadow 0.5s;
}

.sonic-card:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.75);
  transition: box-shadow 0.5s;
}

.skill-pill {
  border-radius: 16px;
  padding: 4px 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  justify-content: center;
}

.circle-container {
  border-radius: 50%;
  width: 100%;
  padding: 2px;
  border: 2px solid #da2528;
}

.content-image {
  border-radius: 16px;
  border: 8px solid #f19b00;
}

.pill-button {
  border-radius: 16px;
  padding: 4px 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  transition: box-shadow 0.5s;
}

.pill-button:hover {
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.75);
  transition: box-shadow 0.5s;
}

.blog-card {
  border-radius: 8px;
  background-color: #593900;
  padding: 1rem;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid #000;
}

.blog-card:hover {
  outline: 4px solid #000;
  transition: outline 0.1s ease-in-out;
}

.blog-container {
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100%;
  border-radius: 16px;
}

/*------ Horizontal Rule from Amdocs component -------*/

.horizontal-rule-grey{
  overflow: hidden;
  text-align: center;
}

.horizontal-rule-grey > span{
  position: relative;
  display: inline-block;
}

.horizontal-rule-grey > span:before, .horizontal-rule-grey > span:after{
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #BABCC0;
  width: 100vw;
}

.horizontal-rule-grey > span:before {
  right: 95%;
}

.horizontal-rule-grey > span:after{
  left: 100%;
}

/* --------------------------------------- */

.horizontal-line-grey {
  width: 100%;
  height: 1px;
  background-color: #BABCC0;
}

.picture-square {
  max-height: 18rem;
  max-width: 18rem;
  background-color: #505050;
}

